/*
ON LOAD PAGE FUNCTION
*/

jQuery( window ).on( 'load', function() {

    $('body').removeClass('is-load');

} );

/*
INITIALIZATION FUNCTIONS
*/
var objlist = {};
var doing_scroll=false;
jQuery( document ).ready( function( $ ) {

    $('.slider-box').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll:1,
        row:2,
        dots:true
    });

    $(".box-link .anchor-link").on("click", function (event) {
        event.preventDefault();
        if(!doing_scroll){
            doing_scroll=true;

            $('.box-link .anchor-link').removeClass('is-active');
            $(this).addClass('is-active');

            let id  = $(this).attr('href'),
                top = $(id).offset().top - 98;
            $('body,html').animate({scrollTop: top}, 1000,function () {
                doing_scroll=false;
            });
        }

    });

    $('.content-header-box a').on('click', function () {
        let id2 = $(this).attr('href'),
            top = $(id2).offset().top - 98;
        $('body,html').animate({scrollTop: top}, 1000,function () {
            doing_scroll=false;
        });
    });



    $('.language').on('click', function () {
        $('.menu-lvl3').toggleClass('is-active')
    });
    //
    $('.menu-lvl3 li a').on('click', function () {

        $('.active-language').text($(this).text());

        $('.menu-lvl3 li a').removeClass('is-active');
        $(this).addClass('is-active');
    });

    $(".box-link > ul > li > a").each(function(){
        objlist[$(this).attr("href")]=$($(this).attr("href")).offset().top;
    });

    setActiveMenuElement();

    $('.burger-menu').on('click', function () {
        $('.box-link').slideToggle();
        $(this).toggleClass('is-active');
        $('body').toggleClass('is-active')
    });

    $('.anchor-link').on('click', function () {
        $('.box-link').slideToggle();
        $('.box-link').removeClass('is-active');
        $('.burger-menu').removeClass('is-active');
        $('body').removeClass('is-active');
    });

} );

/*
ON SCROLL PAGE FUNCTIONS
*/

if($(window).height() > 600){
    $(function(){
        $(document).mouseup(function (e){
            var block = $(".menu-lvl3 ");
            if (!block.is(e.target)
                && block.has(e.target).length === 0) {
                block.removeClass('is-active');
            }
        });
    });
}
jQuery( window ).on( 'scroll', function() {
    if(!doing_scroll){
        setActiveMenuElement();
    }

    var height = $(window).scrollTop();
    if(height > 100){
        $('.menu-box').addClass('is-active');
    } else {
        /*Если меньше 100px удаляем класс для header*/
        $('.menu-box').removeClass('is-active');
    }
});

function setActiveMenuElement() {
    var height = $(window).scrollTop();
    /*Если сделали скролл на 100px задаём новый класс для header*/
    if(height > 1){
        $(".menu-lvl3 ").removeClass('is-active');
    }

    let scrolltoppage = window.pageYOffset;
    let foundProp = null;
    for (var prop in objlist) {
        if(objlist[prop] <= scrolltoppage + 105){
            foundProp=prop;
        }
    }

    $('.anchor-link').removeClass('is-active');
    if(foundProp){
        $('a[href ="'+foundProp +'"]').addClass('is-active')
    }
}